'use strict';

/*
This file is meant for site specific JavaScript. Rename this file to suit the site and will need to be renamed in gulp/scripts.js in the 'babelScripts' and 'concatScripts' tasks.
*/

/*===================================================================
                            Slider - Home
===================================================================*/
if ($('.dola-home-intro-slider__slides').length > 0) {
    $('.dola-home-intro-slider__slides').on('init', function (e, slick, currentSlide) {
        updateTotalSlides(slick.slideCount);
    });

    var dolaHomeSlider = $('.dola-home-intro-slider__slides').slick({
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: $('.dola-home-intro-slider-page__prev-arrow'),
        nextArrow: $('.dola-home-intro-slider-page__next-arrow')
    });

    dolaHomeSlider.on('afterChange', function (e, slick) {
        var slideNumber = slick.currentSlide + 1;
        updateCurrentSlide(slideNumber);
    });
}

//-------------------Slider page number functions--------------------
function updateTotalSlides(number) {
    $('.dola-home-intro-slider-page__total').html(number);
}

function updateCurrentSlide(number) {
    $('.dola-home-intro-slider-page__current').html(number);
}

/*===================================================================
                            Slider - Apply
===================================================================*/
if ($('.dola-apply-intro-slider__slides').length > 0) {
    $('.dola-apply-intro-slider__slides').on('init', function (e, slick, currentSlide) {
        updateTotalSlidesApply(slick.slideCount);
    });

    var dolaApplySlider = $('.dola-apply-intro-slider__slides').slick({
        arrows: true,
        slidesToShow: 1,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: $('.dola-apply-intro-slider-page__prev-arrow'),
        nextArrow: $('.dola-apply-intro-slider-page__next-arrow')
    });

    dolaApplySlider.on('afterChange', function (e, slick) {
        var slideApplyNumber = slick.currentSlide + 1;
        updateCurrentSlideApply(slideApplyNumber);
    });
}

function updateTotalSlidesApply(number) {
    $('.dola-apply-intro-slider-page__total').html(number);
}

function updateCurrentSlideApply(number) {
    $('.dola-apply-intro-slider-page__current').html(number);
}

/*===================================================================
                            Slider - Abroad
===================================================================*/
if ($('.dola-abroad-intro-slider__slides').length > 0) {
    $('.dola-abroad-intro-slider__slides').on('init', function (e, slick, currentSlide) {
        updateTotalSlidesAbroad(slick.slideCount);
    });

    var dolaAbroadSlider = $('.dola-abroad-intro-slider__slides').slick({
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: $('.dola-abroad-intro-slider-page__prev-arrow'),
        nextArrow: $('.dola-abroad-intro-slider-page__next-arrow')
    });

    dolaAbroadSlider.on('afterChange', function (e, slick) {
        var slideNumber = slick.currentSlide + 1;
        updateCurrentSlideAbroad(slideNumber);
    });
}

function updateTotalSlidesAbroad(number) {
    $('.dola-abroad-intro-slider-page__total').html(number);
}

function updateCurrentSlideAbroad(number) {
    $('.dola-abroad-intro-slider-page__current').html(number);
}

/*===================================================================
                            Slider - People
===================================================================*/
if ($('.dola-people-people__tiles').length > 0) {
    $('.dola-people-people__tiles').on('init', function (e, slick, currentSlide) {
        updateTotalSlidesPeople(slick.slideCount);
    });

    var dolaPeopleSlider = $('.dola-people-people__tiles').slick({
        arrows: false,
        slidesToShow: 1,
        centerPadding: '60px',
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [{
            breakpoint: 600,
            settings: "unslick"
        }]
    });

    dolaPeopleSlider.on('afterChange', function (e, slick) {
        var slidePeopleNumber = slick.currentSlide + 1;
        updateCurrentSlidePeople(slidePeopleNumber);
    });
}

//----------------Slider People page number functions----------------
function updateTotalSlidesPeople(number) {
    $('.people-counter__total').html(number);
}

function updateCurrentSlidePeople(number) {
    $('.people-counter__current').html(number);
}